import { messages } from '@/data/translation-messages';

export default defineI18nConfig(() => {
  return {
    legacy: false,
    locale: 'cs',
    fallbackLocale: 'cs',
    messages,
    pluralRules: {
      cs: (choice) => {
        if (choice === 1) {
            return 0
        }
        if (choice > 1 && choice < 5) {
            return 1
        }
        return 2
      },
      sk: (choice) => {
        if (choice === 1) {
            return 0
        }
        if (choice > 1 && choice < 5) {
            return 1
        }
        return 2
      },
      pl: (choice) => {
        if (choice === 1) {
            return 0
        }
        if (choice > 1 && choice < 5) {
            return 1
        }
        return 2
      },
    }
  };
})
